<template>
  <div>
    <vx-card title="Les mails" class="mb-4">
      <div slot="no-body">
        <div class="aps-benin-v2-content">
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            >
              <div>
                <vs-button
                  v-if="checkUserRole('mailing').create"
                  @click="addMailing"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  >Ajouter</vs-button
                >
                <vx-tooltip
                  color="primary"
                  :title="role.notAllowTitle"
                  :text="role.notAllowText"
                  v-else
                >
                  <vs-button
                    :disabled="true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                </vx-tooltip>
              </div>
              <div>
                <vs-dropdown
                  
                  class="dd-actions cursor-pointer ml-4"
                >
                  <div
                    class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      v-if="checkUserRole('mailing').delete"
                      :disabled="mailSelected.length == 0"
                      @click="checkRemoveMultipleMail"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-dropdown-item :disabled="true">
                        <span class="flex items-center">
                          <feather-icon
                            icon="Trash2Icon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>Supprimer la sélection</span>
                        </span>
                      </vs-dropdown-item>
                    </vx-tooltip>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
              <div>
                <div>
                  <vs-dropdown
                    
                    class="dd-actions cursor-pointer ml-4"
                  >
                    <div
                      class="p-3 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
            </div>
            <div>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allWebmail.list.length !== 0">
                  <vs-table
                    multiple
                    @dblSelection="mailingSelectedDouble"
                    :data="allWebmail.list"
                    v-model="mailSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="updatedDate"
                        >Date (dernière modification)</vs-th
                      >
                      <vs-th sort-key="campaign">Campagne</vs-th>
                      <vs-th sort-key="target">Destination</vs-th>
                      <vs-th sort-key="target"
                        >Configuration taux d'ouverture</vs-th
                      >
                      <vs-th sort-key="status">Statut </vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].updatedDate">{{
                          dateElement(data[indextr]).textUpdatedAt
                        }}</vs-td>
                        <vs-td :data="data[indextr].campaign">{{
                          data[indextr].campaign
                        }}</vs-td>
                        <vs-td :data="data[indextr].target">{{
                          displayTarget(data[indextr])
                        }}</vs-td>
                        <vs-td :data="data[indextr].openingRateConfiguration">{{
                          data[indextr].openingRateConfiguration
                        }}</vs-td>
                        <vs-td :data="data[indextr].status">{{
                          statusList.find(
                            status => status.id == data[indextr].status
                          ).label
                        }}</vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <div>
                              <vx-tooltip
                                color="primary"
                                text="Aperçu"
                                v-if="checkUserRole('mailing').show"
                              >
                                <div
                                  class="cursor-pointer"
                                  @click="checkViewWebmail(data[indextr])"
                                >
                                  <feather-icon
                                    icon="EyeIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                                v-else
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="EyeIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                color="primary"
                                title="Modifier"
                                :text="global.denied"
                                v-if="
                                  checkUserRole('mailing').update &&
                                    data[indextr].status != 'project'
                                "
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                color="primary"
                                text="Modifier"
                                v-else-if="
                                  checkUserRole('mailing').update &&
                                    data[indextr].status == 'project'
                                "
                              >
                                <div
                                  class="cursor-pointer ml-6"
                                  @click="checkUpdateWebmail(data[indextr])"
                                >
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                                v-else
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="Edit2Icon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                color="primary"
                                text="Dupliquer"
                                v-if="checkUserRole('mailing').create"
                              >
                                <div
                                  class="cursor-pointer ml-6"
                                  @click="
                                    checkDupplicatedWebmail(data[indextr])
                                  "
                                >
                                  <feather-icon
                                    icon="CopyIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                                v-else
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="CopyIcon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <div>
                              <vx-tooltip
                                color="primary"
                                text="Supprimer"
                                v-if="checkUserRole('mailing').delete"
                              >
                                <div
                                  class="cursor-pointer ml-6"
                                  @click="checkRemoveWebmail(data[indextr])"
                                >
                                  <feather-icon
                                    icon="Trash2Icon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <vx-tooltip
                                color="primary"
                                :title="role.notAllowTitle"
                                :text="role.notAllowText"
                                v-else
                              >
                                <div class="cursor-pointer ml-6">
                                  <feather-icon
                                    icon="Trash2Icon"
                                    class="text-primary"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                            </div>
                            <vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer ml-6">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>

                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="
                                  loadingRemoveWebmail || loadingUpdateWebmail
                                "
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-popup
            class="holamundo"
            title="Ajouter une campagne"
            :active.sync="popupAddWebMailActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Expéditeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="form.senderFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Expéditeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="form.senderEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Expéditeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Expéditeur")
                        }}</span>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Répondeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="form.replyFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Répondeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="form.replyEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Répondeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Répondeur")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="pt-2 pb-2">
                      <vs-checkbox v-model="isPlanWebMail">
                        Planifier</vs-checkbox
                      >
                    </div>
                    <div v-if="isPlanWebMail" class="w-full">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="date"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Date d\'envoie*'"
                            v-model="form.sentDate"
                          />
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="time"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Heure d\'envoie*'"
                            v-model="form.sentTime"
                          />
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Campagne*"
                          v-model="form.campaign"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="campagne"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("campagne")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="w-full">
                      <vs-col
                        v-if="!form.isModule"
                        class="pr-4 mb-2 mt-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-textarea
                            v-model="targetFromProps"
                            label="Destination* : "
                            v-validate="'required'"
                            name="Destination"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first("Destination")
                          }}</span>
                        </div>
                      </vs-col>

                      <vs-col
                        v-else
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Destination*</span>
                          <multiselect
                            v-model="form.target"
                            :options="destinationList"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Destination"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>

                          <span class="text-danger text-sm">{{
                            errors.first("Destination")
                          }}</span>
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div class="w-full">
                        <p class="pl-2 pt-2">Objet*</p>
                        <multiselect
                          v-model="variableGlobalFormOject"
                          :options="allMailVariableList"
                          class="mb-4 mt-2"
                          id="text"
                          placeholder="--Insérer une variable de personnalisation--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                      </div>
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          v-model="form.object"
                          id="texto"
                          @change="onEditorChangeFormObjet($event)"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="objet"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("objet")
                        }}</span>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div
                        class="flex mt-2 mb-3"
                        style="flex-direction: column;"
                      >
                        <div class="pl-2">Message*</div>
                      </div>
                      <div class="w-full">
                        <multiselect
                          v-model="variableGlobalForm"
                          :options="allMailVariableList"
                          class="mb-4"
                          placeholder="--Insérer une variable de personnalisation--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>

                        <quill-editor
                          @focus="onEditorFocusForm($event)"
                          @change="onEditorChangeForm($event)"
                          v-model="form.message"
                        >
                        </quill-editor>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-8"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div
                        class="w-full"
                        style="display: flex;
                        align-items: flex-end;
                        justify-content: space-between;"
                      >
                        <div>
                          <div>
                            <span class=" ml-2">Fichier(s)</span>
                            <div>
                              <div uk-form-custom class="ebsi-upload-file">
                                <input
                                  multiple
                                  type="file"
                                  :value="resetFilesUpload"
                                  v-on:change="checkUploadFile"
                                />
                                <button
                                  style="cursor:pointer"
                                  class="uk-button uk-button-default"
                                  type="button"
                                  tabindex="-1"
                                >
                                  Choisir vos fichiers
                                </button>
                              </div>
                            </div>
                          </div>

                          <span class="text-danger text-sm" v-show="formError"
                            >Erreur lors du chargement du fichier</span
                          >
                        </div>
                      </div>
                      <div
                        class=" mt-3 mb-3"
                        style="
                          display: flex;
                          flex-direction: column;"
                      >
                        <div class="mb-4" v-if="form.filesname.length > 0">
                          <p><b>Liste des fichiers</b></p>
                        </div>
                        <div
                          v-for="(file, index) in form.filesname"
                          :key="file.name"
                          class="center"
                        >
                          <div class="flex">
                            {{ index + 1 }})
                            <b class="pl-2">{{ subString(file) }}</b>
                            <vx-tooltip color="primary" text="Supprimer">
                              <div
                                class="ml-8 cursor-pointer"
                                @click="removeFile(index, 'Add')"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                          </div>
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="displayFileName"
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div
                        class="inputx w-full flex justify-around"
                        style="align-items: center;"
                      >
                        <span>
                          {{ form.fileName }}
                        </span>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="!validateFormAddWebMail || loadingAddWebmail"
                      @click="addWebMail(true)"
                      >{{
                        form.status == "plan" ? "Planifier" : "Envoyer"
                      }}</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      :disabled="!validateFormAddWebMail || loadingAddWebmail"
                      @click="addWebMail(false)"
                      >Enregistrer</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupAddWebMailActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingAddWebmail || loadingSaveSetting"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Dupliquer une campagne"
            :active.sync="popupDuplicatedWebMailActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Expéditeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formDuplicated.senderFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Expéditeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formDuplicated.senderEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Expéditeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Expéditeur")
                        }}</span>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Répondeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formDuplicated.replyFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Répondeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formDuplicated.replyEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Répondeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Répondeur")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="pt-2 pb-2">
                      <vs-checkbox v-model="isDuplicatedPlanWebMail">
                        Planifier</vs-checkbox
                      >
                    </div>
                    <div v-if="isDuplicatedPlanWebMail" class="w-full">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="date"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Date d\'envoie*'"
                            v-model="formDuplicated.sentDate"
                          />
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="time"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Heure d\'envoie*'"
                            v-model="formDuplicated.sentTime"
                          />
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Campagne*"
                          v-model="formDuplicated.campaign"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="campagne"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("campagne")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="w-full">
                      <vs-col
                        v-if="formDuplicated.isModule"
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Destination*</span>
                          <multiselect
                            v-model="formDuplicated.target"
                            :options="destinationList"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Destination"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>

                          <span class="text-danger text-sm">{{
                            errors.first("Destination")
                          }}</span>
                        </div>
                      </vs-col>

                      <vs-col
                        v-else
                        class="pr-4 mb-2 mt-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-textarea
                            v-model="targetFromDuplicated"
                            label="Destinataire* : "
                            v-validate="'required'"
                            name="destinataire"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first("destinataire")
                          }}</span>
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div
                        class="flex  mb-2 mb-4"
                        style="flex-direction: column;"
                      >
                        <p class="pl-2 pt-2">Objet*</p>
                        <p class="pl-2 flex mt-3">
                          <span>{{ allMailVariableText }}</span>
                        </p>
                      </div>
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          v-model="formDuplicated.object"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="objet"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("objet")
                        }}</span>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div class="pl-2 mt-2">Message*</div>

                      <div class="w-full">
                        <multiselect
                          v-model="variableGlobalDuplicate"
                          :options="allMailVariableList"
                          class="mb-4"
                          placeholder="--Insérer une variable de personnalisation--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>
                        <quill-editor
                          @focus="onEditorFocusDuplicated($event)"
                          @change="onEditorChangeDuplicated($event)"
                          v-model="formDuplicated.message"
                        ></quill-editor>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-8"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div
                        class="w-full"
                        style="display: flex;
                            align-items: flex-end;
                            justify-content: space-between;"
                      >
                        <div>
                          <div>
                            <span class=" ml-2">Fichier(s)</span>
                            <div>
                              <div uk-form-custom class="ebsi-upload-file">
                                <input
                                  multiple
                                  type="file"
                                  :value="resetFilesUpload"
                                  v-on:change="checkUploadDuplicatedFile"
                                />
                                <button
                                  style="cursor:pointer"
                                  class="uk-button uk-button-default"
                                  type="button"
                                  tabindex="-1"
                                >
                                  Choisir vos fichiers
                                </button>
                              </div>
                            </div>
                          </div>

                          <span class="text-danger text-sm" v-show="formError"
                            >Erreur lors du chargement du fichier</span
                          >
                        </div>
                      </div>

                      <div
                        class=" mt-3 mb-3"
                        style="
                          display: flex;
                          flex-direction: column;"
                      >
                        <div
                          class="mb-4"
                          v-if="formDuplicated.filesname.length > 0"
                        >
                          <p><b>Liste des fichiers</b></p>
                        </div>
                        <div
                          v-for="(file, index) in formDuplicated.filesname"
                          :key="file.name"
                          class="center"
                        >
                          <div class="flex" v-if="file.filename && file.name">
                            {{ index + 1 }})
                            <b
                              @click="checkViewFile(file)"
                              class="pl-2 cursor-pointer"
                              >{{ subString(file.name) }}</b
                            >
                            <vx-tooltip color="primary" text="Supprimer">
                              <div
                                class="ml-8 cursor-pointer"
                                @click="removeFileFromFilenameDuplicated(index)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                          </div>
                          <div v-else class="flex">
                            {{ index + 1 }}) <b>{{ subString(file) }}</b>
                            <vx-tooltip color="primary" text="Supprimer">
                              <div
                                class="ml-8 cursor-pointer"
                                @click="removeFile(index, 'Duplicated')"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="
                        !validateFormDuplicatedWebMail ||
                          loadingDuplicatedWebmail
                      "
                      @click="duplicateWebMail(true)"
                      >{{
                        formDuplicated.status == "plan"
                          ? "Planifier"
                          : "Envoyer"
                      }}</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      :disabled="
                        !validateFormDuplicatedWebMail ||
                          loadingDuplicatedWebmail
                      "
                      @click="duplicateWebMail(false)"
                      >Enregistrer</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupDuplicatedWebMailActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingDuplicatedWebmail || loadingSaveSetting"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Modifier une campagne"
            :active.sync="popupUpdateWebMailActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Expéditeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formUpdated.senderFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Expéditeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formUpdated.senderEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Expéditeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Expéditeur")
                        }}</span>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Nom Répondeur"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formUpdated.replyFullname"
                        />
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          label="Email Répondeur*"
                          class="inputx w-full mb-2 mt-2"
                          v-model="formUpdated.replyEmail"
                          v-validate="'required|email'"
                          data-vv-validate-on="blur"
                          name="Email Répondeur"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("Email Répondeur")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="pt-2 pb-2">
                      <vs-checkbox v-model="isUpdatedPlanWebMail">
                        Planifier</vs-checkbox
                      >
                    </div>
                    <div v-if="isUpdatedPlanWebMail" class="w-full">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="date"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Date d\'envoie*'"
                            v-model="formUpdated.sentDate"
                          />
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="time"
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Heure d\'envoie*'"
                            v-model="formUpdated.sentTime"
                          />
                        </div>
                      </vs-col>
                    </div>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Campagne*"
                          v-model="formUpdated.campaign"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="campagne"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("campagne")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="w-full">
                      <vs-col
                        v-if="formUpdated.isModule"
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Destination*</span>
                          <multiselect
                            v-model="formUpdated.target"
                            :options="destinationList"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            v-validate="'required'"
                            name="Destination"
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>

                          <span class="text-danger text-sm">{{
                            errors.first("Destination")
                          }}</span>
                        </div>
                      </vs-col>

                      <vs-col
                        v-else
                        class="pr-4 mb-2 mt-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-textarea
                            v-model="targetFromUpdate"
                            label="Destinataire* : "
                            v-validate="'required'"
                            name="destinataire"
                          />
                          <span class="text-danger text-sm">{{
                            errors.first("destinataire")
                          }}</span>
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div
                        class="flex  mb-2 mb-4"
                        style="flex-direction: column;"
                      >
                        <p class="pl-2 pt-2">Objet*</p>
                        <p class="pl-2 flex mt-3">
                          <span>{{ allMailVariableText }}</span>
                        </p>
                      </div>
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          v-model="formUpdated.object"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="objet"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("objet")
                        }}</span>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="left"
                      vs-align="left"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div class="pl-2 mt-2">Message*</div>
                      <div class="w-full">
                        <multiselect
                          v-model="variableGlobalUpdate"
                          :options="allMailVariableList"
                          class="mb-4"
                          placeholder="--Insérer une variable de personnalisation--"
                          label="label"
                          track-by="label"
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                        </multiselect>

                        <quill-editor
                          @focus="onEditorFocusUpdated($event)"
                          @change="onEditorChangeUpdated($event)"
                          v-model="formUpdated.message"
                        ></quill-editor>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-8"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div
                        class="w-full"
                        style="display: flex;
                            align-items: flex-end;
                            justify-content: space-between;"
                      >
                        <div>
                          <div>
                            <span class=" ml-2">Fichier(s)</span>
                            <div>
                              <div uk-form-custom class="ebsi-upload-file">
                                <input
                                  multiple
                                  type="file"
                                  :value="resetFilesUpload"
                                  v-on:change="checkUploadUpdatedFile"
                                />
                                <button
                                  style="cursor:pointer"
                                  class="uk-button uk-button-default"
                                  type="button"
                                  tabindex="-1"
                                >
                                  Choisir vos fichiers
                                </button>
                              </div>
                            </div>
                          </div>

                          <span class="text-danger text-sm" v-show="formError"
                            >Erreur lors du chargement du fichier</span
                          >
                        </div>
                      </div>

                      <div
                        class=" mt-3 mb-3"
                        style="
                          display: flex;
                          flex-direction: column;"
                      >
                        <div
                          class="mb-4"
                          v-if="formUpdated.filesname.length > 0"
                        >
                          <p><b>Liste des fichiers</b></p>
                        </div>
                        <div
                          v-for="(file, index) in formUpdated.filesname"
                          :key="file.name"
                          class="center"
                        >
                          <div class="flex" v-if="file.filename && file.name">
                            {{ index + 1 }})
                            <b
                              @click="checkViewFile(file)"
                              class="pl-2 cursor-pointer"
                              >{{ subString(file.name) }}</b
                            >
                            <vx-tooltip color="primary" text="Supprimer">
                              <div
                                class="ml-8 cursor-pointer"
                                @click="removeFileFromFilenameUpdated(index)"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                          </div>
                          <div v-else class="flex">
                            {{ index + 1 }}) <b>{{ subString(file) }}</b>
                            <vx-tooltip color="primary" text="Supprimer">
                              <div
                                class="ml-8 cursor-pointer"
                                @click="removeFile(index, 'Updated')"
                              >
                                <feather-icon
                                  icon="Trash2Icon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="
                        !validateFormUpdatedWebMail || loadingUpdateWebmail
                      "
                      @click="updateWebMail(true)"
                      >{{
                        formUpdated.status == "plan" ? "Planifier" : "Envoyer"
                      }}</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      :disabled="
                        !validateFormUpdatedWebMail || loadingUpdateWebmail
                      "
                      @click="updateWebMail(false)"
                      >Enregistrer</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupUpdateWebMailActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingUpdateWebmail || loadingSaveSetting"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>

          <vs-popup
            class="holamundo"
            title="Aperçu d'une campagne"
            :active.sync="popupViewWebMailActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <div v-if="isUpdatedPlanWebMail" class="w-full">
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="date"
                            disabled
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Date d\'envoie :'"
                            v-model="formView.sentDate"
                          />
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center;"
                        >
                          <vs-input
                            type="time"
                            disabled
                            class="aps-benin-v2-disable-input-date inputx w-full mb-2 mt-2"
                            :label="'Heure d\'envoie :'"
                            v-model="formView.sentTime"
                          />
                        </div>
                      </vs-col>
                    </div>
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          disabled
                          class="inputx w-full mb-2 mt-2"
                          label="Campagne :"
                          v-model="formView.campaign"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="campagne"
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("campagne")
                        }}</span>
                      </div>
                    </vs-col>

                    <div class="w-full">
                      <vs-col
                        v-if="formView.isModule"
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Destination *</span>
                          <multiselect
                            v-model="formView.target"
                            :options="destinationList"
                            :multiple="true"
                            :close-on-select="false"
                            placeholder="--Choisir--"
                            label="label"
                            track-by="label"
                            disabled
                            required
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>

                      <vs-col
                        v-else
                        class="pr-4 mb-2 mt-4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <vs-textarea
                            v-model="targetFromUpdate"
                            label="Destinataire * : "
                            disabled
                          />
                        </div>
                      </vs-col>
                    </div>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div
                        class="flex  mb-2 mb-4"
                        style="flex-direction: column;"
                      >
                        <p class="pl-2">Objet :</p>
                        <p class="pl-2 flex mt-3">
                          <span>{{ allMailVariableText }}</span>
                        </p>
                      </div>
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          v-model="formView.object"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="objet"
                          disabled
                        />
                        <span class="text-danger text-sm">{{
                          errors.first("objet")
                        }}</span>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                      style="flex-direction: column;"
                    >
                      <div
                        class="flex mt-2 mb-3"
                        style="flex-direction: column;"
                      >
                        <p class="pl-2">Message :</p>
                        <p class="pl-2 flex mt-3">
                          <span>{{ allMailVariableText }}</span>
                        </p>
                      </div>
                      <div class="w-full">
                        <p class="pl-4">
                          <b><span v-html="formView.message"></span></b>
                        </p>
                      </div>
                    </vs-col>
                    <vs-col
                      class="pr-4 mb-2 mt-8"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div
                        class=" mt-3 mb-3"
                        style="
                          display: flex;
                          flex-direction: column;"
                      >
                        <div class="mb-4" v-if="formView.filesname.length > 0">
                          <p><b>Liste des fichiers</b></p>
                        </div>
                        <div
                          v-for="(file, index) in formView.filesname"
                          :key="file.name"
                          class="center"
                        >
                          <div class="flex">
                            {{ index + 1 }})
                            <b
                              class="pl-2 cursor-pointer"
                              @click="checkViewFile(file)"
                              >{{ subString(file.name) }}</b
                            >
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      type="border"
                      @click="popupViewWebMailActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingUpdateWebmail || loadingSaveSetting"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      cursorPositionForm: 0,
      newLength: 0,
      newLengthQuill: 0,
      quillEditorForm: {},
      cursorPositionUpdate: 0,
      quillEditorUpdate: {},
      cursorPositionDuplicate: 0,
      cursorPositionObject: 0,
      quillEditorDuplicate: {},
      formTakeOjet: {},

      variableGlobalForm: "",
      variableGlobalUpdate: "",
      variableGlobalFormOject: "",
      variableGlobalDuplicate: "",

      statusList: [
        { id: "project", label: "Projet" },
        { id: "plan", label: "Planifié" },
        { id: "send", label: "Envoyé" }
      ],
      loadingSaveSetting: false,
      contentInUpdateForm: false,
      contentInForm: false,
      filesListBeforeUpdated: [],
      filesListBeforeDuplicated: [],
      resetFilesUpload: "",
      filesList: [],
      companyList: [],
      formData: [],
      fileDataUp: [],
      formError: "",
      formDataUpdated: [],
      fileDataUpUpdated: [],
      formErrorUpdated: "",

      formDataDuplicated: [],
      fileDataUpDuplicated: [],
      formErrorDuplicated: "",

      displayFileName: false,

      role: {},
      global: {},
      destinationList: [
        { id: "projects_pending", label: "Projets en cours" },
      ],
      form: {
        senderEmail: "",
        replyEmail: "",
        sentDate: "",
        sentTime: "",
        campaign: "",
        object: "",
        message: "",
        target: [],
        isModule: true,
        status: "project",
        filesname: []
      },
      formUpdated: {
        senderEmail: "",
        replyEmail: "",
        sentDate: "",
        sentTime: "",
        campaign: "",
        object: "",
        message: "",
        target: [],
        isModule: true,
        status: "project",
        filesname: []
      },
      formDuplicated: {
        senderEmail: "",
        replyEmail: "",
        sentDate: "",
        sentTime: "",
        campaign: "",
        object: "",
        message: "",
        target: [],
        isModule: true,
        status: "project",
        filesname: []
      },
      formView: {
        senderEmail: "",
        replyEmail: "",
        sentDate: "",
        sentTime: "",
        campaign: "",
        object: "",
        message: "",
        target: [],
        isModule: true,
        filesname: []
      },
      isPlanWebMail: false,
      isUpdatedPlanWebMail: false,
      isDuplicatedPlanWebMail: false,
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingRemoveWebmail: false,
      loadingUpdateWebmail: false,
      loadingDuplicatedWebmail: false,
      loadingAddWebmail: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"],//formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: [
        "N°",
        "Date (dernière modification)",
        "Campagne",
        "Destination",
        "Statut"
      ],
      headerVal: ["index", "lastUpdated", "campaign", "target", "status"],
      activePrompt: false,
      //end export section
      popupAddWebMailActive: false,
      popupUpdateWebMailActive: false,
      popupViewWebMailActive: false,
      popupDuplicatedWebMailActive: false,
      mailSelected: [],
      crrIDs: [],
      targetFromProps: "",
      targetFromUpdate: "",
      targetFromDuplicated: ""
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_mailing
    ) {
      if (!this.checkUserRole("mailing").all)
        this.$router.push({ name: "Home" });
    }

    this.limit = this.rowsTable[1];

    if (
      this.$route.query.name == "broker" ||
      this.$route.query.name == "users"
    ) {
      this.$route.query.data.forEach((el, index) => {
        if (index > 0) {
          this.targetFromProps += `, ${el}`;
        } else {
          this.targetFromProps = el;
        }
      });

      this.form.isModule = false;
      this.popupAddWebMailActive = true;
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("mailing", ["allWebmail"]),
    ...mapGetters("general", [
      "rowsTable",
      "allMailVariableList",
      "allMailVariableText"
    ]),
    validateFormAddWebMail() {
      if (this.form.isModule == false) {
        if (!this.isPlanWebMail) {
          return (
            !this.errors.any() &&
            this.form.senderEmail != "" &&
            this.form.replyEmail != "" &&
            this.form.campaign != "" &&
            this.form.message != "" &&
            this.form.object != "" &&
            this.targetFromProps != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.form.senderEmail != "" &&
            this.form.replyEmail != "" &&
            this.form.sentDate != "" &&
            this.form.sentTime != "" &&
            this.form.campaign != "" &&
            this.form.message != "" &&
            this.form.object != "" &&
            this.targetFromProps != ""
          );
        }
      } else {
        if (!this.isPlanWebMail) {
          return (
            !this.errors.any() &&
            this.form.senderEmail != "" &&
            this.form.replyEmail != "" &&
            this.form.campaign != "" &&
            this.form.message != "" &&
            this.form.object != "" &&
            this.form.target.length != 0
          );
        } else {
          return (
            !this.errors.any() &&
            this.form.senderEmail != "" &&
            this.form.replyEmail != "" &&
            this.form.sentDate != "" &&
            this.form.sentTime != "" &&
            this.form.campaign != "" &&
            this.form.message != "" &&
            this.form.object != "" &&
            this.form.target.length != 0
          );
        }
      }
    },
    validateFormUpdatedWebMail() {
      if (!this.formUpdated.isModule) {
        if (!this.isUpdatedPlanWebMail) {
          return (
            !this.errors.any() &&
            this.formUpdated.replyEmail != "" &&
            this.formUpdated.senderEmail != "" &&
            this.formUpdated.campaign != "" &&
            this.formUpdated.object != "" &&
            this.formUpdated.message != "" &&
            this.targetFromUpdate != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.formUpdated.replyEmail != "" &&
            this.formUpdated.senderEmail != "" &&
            this.formUpdated.sentDate != "" &&
            this.formUpdated.sentTime != "" &&
            this.formUpdated.campaign != "" &&
            this.formUpdated.object != "" &&
            this.formUpdated.message != "" &&
            this.targetFromUpdate != ""
          );
        }
      } else {
        if (!this.isUpdatedPlanWebMail) {
          return (
            !this.errors.any() &&
            this.formUpdated.replyEmail != "" &&
            this.formUpdated.senderEmail != "" &&
            this.formUpdated.campaign != "" &&
            this.formUpdated.object != "" &&
            this.formUpdated.message != "" &&
            this.formUpdated.target.length != 0
          );
        } else {
          return (
            !this.errors.any() &&
            this.formUpdated.replyEmail != "" &&
            this.formUpdated.senderEmail != "" &&
            this.formUpdated.sentDate != "" &&
            this.formUpdated.sentTime != "" &&
            this.formUpdated.campaign != "" &&
            this.formUpdated.object != "" &&
            this.formUpdated.message != "" &&
            this.formUpdated.target.length != 0
          );
        }
      }
    },
    validateFormDuplicatedWebMail() {
      if (!this.formDuplicated.isModule) {
        if (!this.isDuplicatedPlanWebMail) {
          return (
            !this.errors.any() &&
            this.formDuplicated.replyEmail != "" &&
            this.formDuplicated.senderEmail != "" &&
            this.formDuplicated.campaign != "" &&
            this.formDuplicated.object != "" &&
            this.formDuplicated.message != "" &&
            this.targetFromDuplicated != ""
          );
        } else {
          return (
            !this.errors.any() &&
            this.formDuplicated.replyEmail != "" &&
            this.formDuplicated.senderEmail != "" &&
            this.formDuplicated.sentDate != "" &&
            this.formDuplicated.sentTime != "" &&
            this.formDuplicated.campaign != "" &&
            this.formDuplicated.object != "" &&
            this.formDuplicated.message != "" &&
            this.targetFromDuplicated != ""
          );
        }
      } else {
        if (!this.isDuplicatedPlanWebMail) {
          return (
            !this.errors.any() &&
            this.formDuplicated.replyEmail != "" &&
            this.formDuplicated.senderEmail != "" &&
            this.formDuplicated.campaign != "" &&
            this.formDuplicated.object != "" &&
            this.formDuplicated.message != "" &&
            this.formDuplicated.target.length != 0
          );
        } else {
          return (
            !this.errors.any() &&
            this.formDuplicated.replyEmail != "" &&
            this.formDuplicated.senderEmail != "" &&
            this.formDuplicated.sentDate != "" &&
            this.formDuplicated.sentTime != "" &&
            this.formDuplicated.campaign != "" &&
            this.formDuplicated.object != "" &&
            this.formDuplicated.message != "" &&
            this.formDuplicated.target.length != 0
          );
        }
      }
    }
  },
  methods: {
    ...mapActions("general", ["applyUploadFile"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions("mailing", [
      "applyGetWebmail",
      "applyGetAllWebMail",
      "applyAddWebMail",
      "applyPutWebMail",
      "applyRemoveWebMail"
    ]),

    /* Form Objet */
    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end
      };
    },

    onEditorChangeFormObjet(position) {
      let input = document.getElementById("texto");
      input.focus();
      this.cursorPositionObject = this.getInputSelection(input).start;
      this.formTakeOjet = position;
    },
    insertTextFormObjet(data) {
      this.form.object = [
        this.form.object.slice(0, this.cursorPositionObject),
        `${data} `,
        this.form.object.slice(this.cursorPositionObject)
      ].join("");
      this.cursorPositionObject = this.form.object.length;
      console.log("objet:", this.form.object);
    },
    /* End Form Objet */

    /* Form*/
    onEditorFocusForm(quill) {
      this.cursorPositionForm = quill.getSelection();
      this.quillEditorForm = quill;
    },
    onEditorChangeForm(quill) {
      this.cursorPositionForm = quill.quill.getSelection(quill).index;
      this.quillEditorForm = quill.quill;
    },

    insertTextForm(data) {
      this.quillEditorForm.insertText(this.cursorPositionForm, `${data} `);
      this.cursorPositionForm = this.form.message.length - 7;
    },
    /* End Form*/

    /* Form Updated*/
    onEditorChangeUpdated(quill) {
      this.cursorPositionUpdate = quill.quill.getSelection();
      this.quillEditorUpdate = quill.quill;
    },

    onEditorFocusUpdated(quill) {
      this.cursorPositionUpdate = quill.getSelection();
      this.quillEditorUpdate = quill;
    },

    insertTextUpdated(data) {
      this.quillEditorUpdate.insertText(this.cursorPositionUpdate, `${data} `);
    },
    /* End Form Updated*/

    /* Form Duplicated*/
    onEditorChangeDuplicated(quill) {
      console.log("FIXXX", quill);
      this.cursorPositionDuplicate = quill.quill.getSelection();
      this.quillEditorDuplicate = quill.quill;
    },

    onEditorFocusDuplicated(quill) {
      this.cursorPositionDuplicate = quill.getSelection();
      this.quillEditorDuplicate = quill;
    },

    insertTextDuplicated(data) {
      this.quillEditorDuplicate.insertText(
        this.cursorPositionDuplicate,
        `${data} `
      );
    },
    /* End Form Duplicated*/
    addMailing() {
      this.popupAddWebMailActive = true;
      this.variableGlobal = "";
      this.quillEditor = {};
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },

    dateElement(data) {
      return mixin.methods.dateElement(data);
    },

    mailingSelectedDouble(item) {
      if (this.checkUserRole("mailing").show) {
        this.checkViewWebmail(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
    async updateWebMail(isSend) {
      this.loadingUpdateWebmail = true;

      if (this.formUpdated.filesname.length > 0) {
        await this.uploadedFiles("Updated")
          .then(res => {
            console.log(res);
            this.acceptAlert(mixin.methods.message("mailing", "file").success);

            setTimeout(async () => {
              this.fetchApplyUpdateWebmail(isSend);
            }, 1000);
          })
          .catch(() => {
            this.acceptAlert(mixin.methods.message("mailing", "file").error);

            this.fetchApplyUpdateWebmail(isSend);
          });
      } else {
        this.fetchApplyUpdateWebmail(isSend);
      }
    },
    verification(evt) {
      console.log("evt", evt);
    },
    async duplicateWebMail(isSend) {
      this.loadingDuplicatedWebmail = true;

      if (this.formDuplicated.filesname.length > 0) {
        await this.uploadedFiles("Duplicated")
          .then(res => {
            console.log(res);
            this.acceptAlert(mixin.methods.message("mailing", "file").success);
            setTimeout(async () => {
              this.fetchApplyDuplicatedWebmail(isSend);
            }, 1000);
          })
          .catch(() => {
            this.acceptAlert(mixin.methods.message("mailing", "file").error);
            this.fetchApplyDuplicatedWebmail(isSend);
          });
      } else {
        this.fetchApplyDuplicatedWebmail(isSend);
      }
    },

    async addWebMail(isSend) {
      this.loadingAddWebmail = true;
      if (this.form.filesname.length > 0) {
        await this.uploadedFiles("Add")
          .then(res => {
            console.log(res);
            this.acceptAlert(mixin.methods.message("mailing", "file").success);
            setTimeout(async () => {
              this.fetchApplyAddWebmail(isSend);
            }, 1000);
          })
          .catch(() => {
            this.acceptAlert(mixin.methods.message("mailing", "file").error);
            this.fetchApplyAddWebmail(isSend);
          });
      } else {
        this.fetchApplyAddWebmail(isSend);
      }
    },
    checkUploadFile(evt) {
      console.log("evt", evt);
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();

        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;

        allFilesChecked.push(evt.target.files[i].name);

        formDataLength++;
      }
      this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      this.displayFileName = true;
    },
    checkUploadUpdatedFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataUpdated.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataUpdated[formDataLength + 1] = formDataTmp;
        this.formErrorUpdated = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }

      console.log("list fichier", allFilesChecked);
      this.formUpdated.filesname = [
        ...this.formUpdated.filesname,
        ...allFilesChecked
      ];
      this.displayFileName = true;
      console.log(this.formUpdated.filesname);
    },

    checkUploadDuplicatedFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formDataDuplicated.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formDataDuplicated[formDataLength + 1] = formDataTmp;
        this.formErrorDuplicated = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }

      console.log("list fichier", allFilesChecked);
      this.formDuplicated.filesname = [
        ...this.formDuplicated.filesname,
        ...allFilesChecked
      ];
      this.displayFileName = true;
      console.log(this.formDuplicated.filesname);
    },

    async uploadedFiles(option) {
      let _this = this;
      if (option == "Add") {
        let productPromises = [];
        this.formData.forEach(function(ele, index) {
          productPromises.push(
            (async function(ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUp.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      } else if (option == "Duplicated") {
        let productPromises = [];
        this.formDataDuplicated.forEach(function(ele, index) {
          productPromises.push(
            (async function(ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUpDuplicated.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      } else {
        let productPromises = [];
        this.formDataUpdated.forEach(function(ele, index) {
          productPromises.push(
            (async function(ele) {
              const res = await _this.applyUploadFile(ele);
              _this.fileDataUpUpdated.push({
                filename: res.data[0].uploadedName,
                name: res.data[0].filename
              });
            })(ele)
          );
        });
        await Promise.all(productPromises);
      }
    },
    subString(data) {
      if (data != undefined && data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    removeFile(data, option) {
      if (option == "Add") {
        this.formData.splice(data, 1);
        this.form.filesname.splice(data, 1);
      } else if (option == "Duplicated") {
        this.formDataDuplicated.splice(data, 1);
        this.formDuplicated.filesname.splice(data, 1);
      } else {
        this.formDataUpdated.splice(data, 1);
        this.formUpdated.filesname.splice(data, 1);
      }
    },
    removeFileFromFilenameDuplicated(item) {
      let data = [...this.formDuplicated.filesname];
      const index = this.formDuplicated.filesname.findIndex(el =>
        el.filename ? el.filename === item.filename : el === item.filename
      );
      data.splice(index, 1);
      this.formDuplicated.filesname = data;
      this.filesListBeforeDuplicated = data;
    },
    removeFileFromFilenameUpdated(item) {
      let data = [...this.formUpdated.filesname];
      const index = this.formUpdated.filesname.findIndex(el =>
        el.filename ? el.filename === item.filename : el === item.filename
      );
      data.splice(index, 1);
      this.formUpdated.filesname = data;
      this.filesListBeforeUpdated = data;
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allWebmail.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            lastUpdated: el.lastUpdated,
            campaign: el.campaign,
            target: el.target,
            status: el.status
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES MAILINGS"
        );
      } else {
        let tableToPrint = [];
        this.allWebmail.list.forEach(el => {
          let jsonTmp = {
            lastUpdated: el.lastUpdated,
            campaign: el.campaign,
            target: el.target,
            status: el.status
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = ["type", "firstname", "lastname", "email", "status"];

        let headerTitleTmp = [
          "Date (dernière modification)",
          "Campagne",
          "Destination",
          "Statut"
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },

    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    async fetchApplyGetAllWebmail(data) {
      this.loading = true;

      try {
        await this.applyGetAllWebMail(data);

        this.total = this.allWebmail.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async fetchApplyAddWebmail(isSend) {
      if (!this.validateFormAddWebMail) return false;
      let targetList = [];

      if (this.form.isModule) {
        this.form.target.forEach(el => {
          targetList.push(el.id);
        });
      } else {
        targetList = [...this.form.target];
      }

      let data = {
        ...this.form,
        target: targetList,
        filesname: [...this.fileDataUp]
      };
      this.loadingAddWebmail = true;

      try {
        if (isSend) {
          if (!this.isPlanWebMail) {
            await this.applyAddWebMail({ ...data, status: "send" });
            this.acceptAlert(
              mixin.methods.message("mailing", "record").success
            );
          } else {
            await this.applyAddWebMail({ ...data, status: "plan" });
            this.acceptAlert(
              mixin.methods.message("mailing", "planning").success
            );
          }
        } else {
          await this.applyAddWebMail({ ...data, status: "project" });
          this.acceptAlert(mixin.methods.message("mailing", "add").success);
        }
        this.loadingAddWebmail = false;
        this.formData = [];
        this.displayFileName = false;
        this.fileDataUp = [];

        let isModule = this.form.isModule;

        this.form = {
          senderEmail: "",
          replyEmail: "",
          sentDate: "",
          sentTime: "",
          campaign: "",
          object: "",
          destination: [],
          isModule: isModule,
          filesname: []
        };
        this.isPlanWebMail = false;
        this.popupAddWebMailActive = false;
      } catch (err) {
        this.loadingAddWebmail = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddWebMailActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("mailing", "add").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkUpdateWebmail(item) {
      console.log("les mail:", item);
      this.variableGlobal = "";
      this.quillEditor = {};
      this.formUpdated.filesname = [];
      item.status == "plan"
        ? (this.isUpdatedPlanWebMail = true)
        : (this.isUpdatedPlanWebMail = false);

      this.crrID = item.id;
      let targetList = [];

      if (!item.isModule) {
        targetList = [...item.target];
        this.targetFromUpdate = [...item.target].toString();
        this.formUpdated = { ...item };
      } else {
        item.target.forEach(el => {
          if (this.destinationList.find(target => target.id == el)) {
            targetList.push(
              this.destinationList.find(target => target.id == el)
            );
          }
        });
        this.formUpdated = { ...item, target: targetList };
      }

      console.log(item, targetList);
      this.filesListBeforeUpdated = this.formUpdated.filesname;
      this.popupUpdateWebMailActive = true;
    },
    checkDupplicatedWebmail(item) {
      this.variableGlobal = "";
      this.quillEditor = {};

      this.formDuplicated.filesname = [];
      item.status == "plan"
        ? (this.isDuplicatedPlanWebMail = true)
        : (this.isDuplicatedPlanWebMail = false);

      this.crrID = item.id;
      let targetList = [];

      if (!item.isModule) {
        targetList = [...item.target];
        this.targetFromDuplicated = [...item.target].toString();
        this.formDuplicated = { ...item };
      } else {
        item.target.forEach(el => {
          if (this.destinationList.find(target => target.id == el)) {
            targetList.push(
              this.destinationList.find(target => target.id == el)
            );
          }
        });
        this.formDuplicated = { ...item, target: targetList };
      }

      this.filesListBeforeDuplicated = this.formDuplicated.filesname;
      this.popupDuplicatedWebMailActive = true;
    },
    checkViewWebmail(item) {
      item.status == "plan"
        ? (this.isUpdatedPlanWebMail = true)
        : (this.isUpdatedPlanWebMail = false);

      this.formView.filesname = [];
      this.targetFromUpdate = "";
      this.crrID = item.id;
      let targetList = [];

      if (!item.isModule) {
        item.target.forEach((el, index) => {
          if (index > 0) {
            this.targetFromUpdate += `, ${el}`;
          } else {
            this.targetFromUpdate = el;
          }
        });
      } else {
        item.target.forEach(el => {
          if (this.destinationList.find(target => target.id == el)) {
            targetList.push(
              this.destinationList.find(target => target.id == el)
            );
          }
        });
      }

      this.formView = { ...item, target: targetList };

      this.popupViewWebMailActive = true;
    },
    displayTarget(data) {
      let targetList = "";

      if (!data.isModule) {
        data.target.forEach((el, index) => {
          if (index == 0) {
            targetList += el;
          } else {
            targetList += " - " + el;
          }
        });
      } else {
        data.target.forEach((el, index) => {
          if (this.destinationList.find(target => target.id == el)) {
            if (index == 0) {
              targetList += this.destinationList.find(target => target.id == el)
                .label;
            } else {
              targetList +=
                " - " +
                this.destinationList.find(target => target.id == el).label;
            }
          }
        });
      }

      return targetList;
    },
    async fetchApplyUpdateWebmail(isSend) {
      if (!this.validateFormUpdatedWebMail) return false;

      let targetList = [];

      if (this.formUpdated.isModule) {
        this.formUpdated.target.forEach(el => {
          targetList.push(el.id);
        });
      } else {
        targetList = [...this.formUpdated.target];
      }
      let data = {
        mailingId: this.crrID,
        ...this.formUpdated,
        target: targetList,
        filesname: [...this.filesListBeforeUpdated, ...this.fileDataUpUpdated]
      };
      this.loadingUpdateWebmail = true;

      try {
        if (isSend) {
          await this.applyAddWebMail(data);
          this.acceptAlert(
          mixin.methods.message("mailing", "record").success
        );
        } else {
          await this.applyPutWebMail(data);
        this.acceptAlert(
          mixin.methods.message("mailing", "update").success
        );
        }

        this.formUpdated = {
          senderEmail: "",
          replyEmail: "",
          sentDate: "",
          sentTime: "",
          campaign: "",
          object: "",
          destination: [],
          filesname: []
        };
        this.formDataUpdated = [];
        this.displayFileName = false;
        this.fileDataUpUpdated = [];
        this.filesListBeforeUpdated = [];

        this.loadingUpdateWebmail = false;
        this.popupUpdateWebMailActive = false;
      } catch (err) {
        this.loadingUpdateWebmail = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdateWebMailActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyDuplicatedWebmail(isSend) {
      if (!this.validateFormDuplicatedWebMail) return false;

      let targetList = [];

      if (this.formDuplicated.isModule) {
        this.formDuplicated.target.forEach(el => {
          targetList.push(el.id);
        });
      } else {
        targetList = [...this.formDuplicated.target];
      }

      let data = {
        ...this.formDuplicated,
        target: targetList,
        filesname: [
          ...this.filesListBeforeDuplicated,
          ...this.fileDataUpDuplicated
        ]
      };
      this.loadingDuplicatedWebmail = true;

      try {
        if (isSend) {
          await this.applyAddWebMail(data);
          this.acceptAlert(mixin.methods.message("mailing", "record").success);
        } else {
          await this.applyAddWebMail(data);
          this.acceptAlert(
            mixin.methods.message("mailing", "duplicate").success
          );
        }

        this.formDuplicated = {
          senderEmail: "",
          replyEmail: "",
          sentDate: "",
          sentTime: "",
          campaign: "",
          object: "",
          destination: [],
          filesname: []
        };
        this.formDataDuplicated = [];
        this.displayFileName = false;
        this.fileDataUpDuplicated = [];
        this.filesListBeforeDuplicated = [];

        this.loadingDuplicatedWebmail = false;
        this.popupDuplicatedWebMailActive = false;
      } catch (err) {
        this.loadingDuplicatedWebmail = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupDuplicatedWebMailActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("mailing", "add").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveMultipleMail() {
      this.mailSelected.forEach(element => {
        this.crrIDs.push(element.id);
      });
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchRemoveMultipleWebmail
      });
    },
    checkViewFile(item) {
      console.log("item", item);
      window.open(this.showFileData(item.filename), "_blank");
    },

    showFileData(data) {
      return `${this.$config.serverURL}/downloads/${data}`;
    },
    async fetchRemoveMultipleWebmail() {
      let data = {
        ids: this.crrIDs
      };

      this.loading = true;
      try {
        await this.applyRemoveWebMail(data);
        this.loading = false;
        this.acceptAlert(
          mixin.methods.message("mailing", "multipleDeletion").success
        );
        this.crrIDs = [];
        this.mailSelected = [];
      } catch (err) {
        this.loading = false;
        this.crrIDs = [];
        this.mailSelected = [];

        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("mailing", "multipleDeletion").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveWebmail(webMail) {
      this.crrID = webMail.id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer cet élément ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveWebmail
      });
    },
    async fetchApplyRemoveWebmail() {
      this.woIsAction = this.crrID;
      let data = {
        ids: [this.crrID]
      };
      this.loadingRemoveWebmail = true;
      try {
        await this.applyRemoveWebMail(data);
        this.loadingRemoveWebmail = false;
        this.acceptAlert(mixin.methods.message("mailing", "delete").success);

        this.woIsAction = "";
        this.mailSelected = [];
      } catch (err) {
        this.loadingRemoveWebmail = false;
        this.woIsAction = "";
        this.mailSelected = [];
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach(item => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Utilisateur n'existe pas"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("mailing", "delete").error
                  );

                  break;
              }
            });
          }
        }
      }
    }
  },
  watch: {
    variableGlobalForm() {
      if (this.quillEditorForm.selection) {
        this.insertTextForm(this.variableGlobalForm.id);
      }
    },
    variableGlobalUpdate() {
      if (this.quillEditorUpdate.selection) {
        this.insertTextUpdated(this.variableGlobalUpdate.id);
      }
    },
    variableGlobalDuplicate() {
      if (this.quillEditorDuplicate.selection) {
        this.insertTextDuplicated(this.variableGlobalDuplicate.id);
      }
    },
    /* Form Objet */
    variableGlobalFormOject() {
      if (this.formTakeOjet) {
        this.insertTextFormObjet(this.variableGlobalFormOject.id);
      }
    },

    popupAddWebMailActive() {
      if (!this.form.isModule && !this.popupAddWebMailActive) {
        this.$router.go(-1);
      }
    },

    targetFromProps() {
      console.log(this.targetFromProps.split(","));
      this.form.target = this.targetFromProps.split(",");
    },
    targetFromUpdate() {
      console.log("klfjkl jef", this.targetFromUpdate);
      this.formUpdated.target = this.targetFromUpdate.split(",");
      this.formView.target = this.targetFromUpdate.split(",");
    },
    isPlanWebMail() {
      this.isPlanWebMail == true
        ? (this.form.status = "plan")
        : (this.form.status = "project");
    },

    isUpdatedPlanWebMail() {
      this.isUpdatedPlanWebMail == true
        ? (this.formUpdated.status = "plan")
        : (this.formUpdated.status = "project");
    },

    isDuplicatedPlanWebMail() {
      this.isDuplicatedPlanWebMail == true
        ? (this.formDuplicated.status = "plan")
        : (this.formDuplicated.status = "project");
    },

    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllWebmail({
        limit: this.limit,
        skip: this.skip
      });
    },

    limit() {
      this.skip = 0;
      this.fetchApplyGetAllWebmail({
        limit: this.limit,
        skip: this.skip
      });
    }
  },
  components: {
    quillEditor,
    ClipLoader,
    Multiselect
  }
};
</script>

<style lang="scss">
.ql-editor {
  height: 120px;
}
</style>
